import React, { useEffect, useRef } from 'react';
import { trans } from '../../../../shared/js/utils/translation';

// results komponenta

const highlightPhrase = (phrase, searchPhrase) => phrase.replaceAll(new RegExp(`(${searchPhrase})`, 'gi'), '<b>$1</b>');

export const Result = ({ result, detailUrl, searchPhrase }) => {
    const ref = useRef(null);

    useEffect(() => {
        if (!result && !ref.current) {
            return false;
        }

        const maxResultsHeight = window.innerHeight - ref.current.getBoundingClientRect().top;
        ref.current.style.maxHeight = `${maxResultsHeight - 50}px`;
    }, [result]);

    return (
        <div className={`whisperer-head-wrapper-result__results ${result ? '' : 'd-none'}`} ref={ref}>
            {result ? (
                <>
                    {/* produkty */}

                    {result.products && result.products.length ? (
                        <>
                            <h4 className="whisperer-head-wrapper-result__results_header">
                                <span>
                                    {trans().whisperer.products}

                                    {result.productsTotalCount !== null ? <> ({result.productsTotalCount})</> : null}
                                </span>
                            </h4>
                            {result.products.map((product) => {
                                const className = `whisperer-head-wrapper-result__results_item whisperer-head-wrapper-result__results_item--${
                                    product.image ? 'product' : 'category'
                                }`;

                                return (
                                    <a key={product.url} className={className} href={product.url}>
                                        {!!product.image && <img src={product.image} alt={product.label} />}
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: highlightPhrase(product.label, searchPhrase),
                                            }}
                                        />
                                    </a>
                                );
                            })}
                        </>
                    ) : null}

                    {/* kategorie */}

                    {result.categories && result.categories.length ? (
                        <>
                            <h4 className="whisperer-head-wrapper-result__results_header">
                                <span>
                                    {trans().whisperer.categories}

                                    {result.categoriesTotalCount !== null ? (
                                        <> ({result.categoriesTotalCount})</>
                                    ) : null}
                                </span>
                            </h4>
                            {result.categories.map((category) => (
                                <a
                                    key={category.url}
                                    className="whisperer-head-wrapper-result__results_item whisperer-head-wrapper-result__results_item--category"
                                    href={category.url}
                                >
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: highlightPhrase(category.label, searchPhrase),
                                        }}
                                    />
                                </a>
                            ))}
                        </>
                    ) : null}

                    {/* obsah */}

                    {(result.articles && result.articles.length) || (result.advices && result.advices.length) ? (
                        <>
                            <h4 className="whisperer-head-wrapper-result__results_header">
                                <span>
                                    {trans().whisperer.content}

                                    {result.othersTotalCount !== null ? <> ({result.othersTotalCount})</> : null}
                                </span>
                            </h4>

                            {/* články */}
                            {result.articles && result.articles.length ? (
                                <>
                                    {result.articles.map((articles) => (
                                        <a
                                            key={articles.url}
                                            className="whisperer-head-wrapper-result__results_item whisperer-head-wrapper-result__results_item--article"
                                            href={articles.url}
                                        >
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        (articles.url.indexOf('article') > -1 ||
                                                        articles.url.indexOf('artikel') > -1 ||
                                                        articles.url.indexOf('magazin') > -1
                                                            ? trans().whisperer.magazine + ': '
                                                            : '') + highlightPhrase(articles.label, searchPhrase),
                                                }}
                                            />
                                        </a>
                                    ))}
                                </>
                            ) : null}

                            {/* poradna */}
                            {result.advices && result.advices.length ? (
                                <>
                                    {result.advices.map((advices) => (
                                        <a
                                            key={advices.url}
                                            className="whisperer-head-wrapper-result__results_item whisperer-head-wrapper-result__results_item--article"
                                            href={advices.url}
                                        >
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        trans().whisperer.advices +
                                                        ': ' +
                                                        highlightPhrase(advices.label, searchPhrase),
                                                }}
                                            />
                                        </a>
                                    ))}
                                </>
                            ) : null}
                        </>
                    ) : null}

                    {/* Naposled hledane */}

                    {result.latest && result.latest.length ? (
                        <>
                            <h4 className="whisperer-head-wrapper-result__results_header">
                                <span>Naposledy hledané</span>
                            </h4>

                            {result.latest.map((suggestedItem) => (
                                <a
                                    key={suggestedItem.url}
                                    href={suggestedItem.url}
                                    className="whisperer-head-wrapper-result__results_item whisperer-head-wrapper-result__results_item--article"
                                >
                                    {suggestedItem.label}
                                </a>
                            ))}
                        </>
                    ) : null}

                    {/* Casto hledane */}

                    {result.frequently && result.frequently.length ? (
                        <>
                            <h4 className="whisperer-head-wrapper-result__results_header">
                                <span>{trans().whisperer.frequentlySearched}</span>
                            </h4>

                            {result.frequently.map((suggestedItem) => (
                                <a
                                    key={suggestedItem.url}
                                    href={suggestedItem.url}
                                    className="whisperer-head-wrapper-result__results_item whisperer-head-wrapper-result__results_item--article"
                                >
                                    {suggestedItem.label}
                                </a>
                            ))}
                        </>
                    ) : null}

                    {/* všechny výsledky */}

                    {!result.isDefault ? (
                        (result.articles && result.articles.length) ||
                        (result.advices && result.advices.length) ||
                        (result.products && result.products.length) ||
                        (result.categories && result.categories.length) ? (
                            <div className="whisperer-head-wrapper-result__show_all">
                                <a href={detailUrl} className="btn btn--primary">
                                    {trans().whisperer.allResults}
                                </a>
                            </div>
                        ) : (
                            <div className="whisperer-head-wrapper-result__empty">{trans().whisperer.noItems}</div>
                        )
                    ) : null}
                </>
            ) : null}
        </div>
    );
};

import { createRoot } from 'react-dom/client';

export const initComponent = (container, component) => {
    if (container) {
        const root = createRoot(container);

        root.render(component);
    } else {
        console.error('invalid DOM element/s for mounting');
    }
};

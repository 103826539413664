import React, { useCallback, useState, useEffect } from 'react';
import { createDebouncedFunc, makeXhrRequest } from '../../../../shared/js/utils/global-helpers';
import { Result } from './result';
import { initComponent } from '../../../../shared/js/react/init-component';

// whisperer react app

const App = ({ container, debounceTimeout, minChars, queryUrl, detailUrl, onShownChange, suggestedItems }) => {
    // state

    const [shown, setShown] = useState(false);
    const [result, setResult] = useState(null);

    // helpers

    const getSearchPhrase = useCallback(() => container.querySelector('input[type="search"]').value, [container]);

    const getDetailUrl = () => detailUrl + '?query=' + encodeURIComponent(getSearchPhrase());

    const submitHandler = useCallback(
        (event) => {
            event ? event.preventDefault() : null;
            const value = getSearchPhrase();
            if (value.length > 0) {
                if (value.length < minChars) {
                    setResult(false);
                    return;
                }

                makeXhrRequest('GET', queryUrl, null, { query: value }, ({ body }) => {
                    setResult(JSON.parse(body));
                });
            } else {
                setResult({
                    // Fix unikátních hodnot záznamů posledního hledání
                    latest: [...new Set(suggestedItems.latest.map(JSON.stringify))].map(JSON.parse),
                    frequently: suggestedItems.frequently,
                    isDefault: true,
                });
            }
        },
        [getSearchPhrase, minChars, queryUrl, suggestedItems]
    );

    const typeHandler = createDebouncedFunc((event) => {
        // Enter a Tab zahazujeme
        if (event.keyCode === 13 || event.keyCode === 9) {
            return;
        }

        submitHandler(event);
    }, debounceTimeout);

    // lifecycle

    useEffect(() => {
        container.querySelector('input[type="search"]').addEventListener('keyup', typeHandler);

        return () => {
            container.querySelector('input[type="search"]').removeEventListener('keyup', typeHandler);
        };
    }, [container, typeHandler]);

    useEffect(() => {
        if (onShownChange) {
            onShownChange(shown);
        }

        if (!shown) {
            return;
        }

        const clickHandler = (event) => {
            // Click do vyhledavaciho formulare ho nezavre
            if (event.target.closest('#search-input-wrapper') !== null) {
                return;
            }

            setShown(false);
        };

        document.body.addEventListener('click', clickHandler);

        return () => {
            document.body.addEventListener('click', clickHandler);
        };
    }, [container, onShownChange, shown]);

    useEffect(() => {
        if (onShownChange) {
            onShownChange(shown);
        }

        if (shown) {
            return;
        }

        const focusHandler = () => {
            submitHandler(null);

            setShown(true);
        };

        container.querySelector('input[type="search"]').addEventListener('focus', focusHandler);

        return () => {
            document.body.removeEventListener('focus', focusHandler);
        };
    }, [container, onShownChange, shown, submitHandler]);

    // output

    return shown ? <Result result={result} detailUrl={getDetailUrl()} searchPhrase={getSearchPhrase()} /> : null;
};

// boostrap (připojení do DOMu)

export const initWhisperer = (
    container,
    destination,
    debounceTimeout,
    minChars,
    queryUrl,
    detailUrl,
    onShownChange,
    suggestedItems
) =>
    initComponent(
        destination,
        <App
            container={container}
            debounceTimeout={debounceTimeout || 500}
            minChars={minChars || 3}
            queryUrl={queryUrl}
            detailUrl={detailUrl}
            onShownChange={onShownChange}
            suggestedItems={suggestedItems}
        />
    );
